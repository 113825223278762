import React, {useState} from 'react'
import { FaAngleDown ,FaAngleUp, FaBarcode } from 'react-icons/fa';
import { Button,Col } from 'react-bootstrap';
import './CourseVisualBlock.css';

export default function CourseVisualBlock(props) {
const [showDescription,setShowDescription]=useState(false);
const handleToggleShowDescription=()=>{
    setShowDescription(!showDescription);
}
  return (

    <Col key={props.index} lg={showDescription?12:4} md={showDescription?12:6} xs={12} sm={12}>
        <div className="componentCourseCode"><FaBarcode /> {props.course.code}</div>
        
            <div className="componentCourseName">{props.course.name}</div>
            {showDescription ?<><div className="componentCourseDesciption">{props.course.description}</div><Button onClick={handleToggleShowDescription} variant="light" className="componentToggleButton"><FaAngleUp/></Button></>:<Button onClick={handleToggleShowDescription} variant="light" className="componentToggleButton"><FaAngleDown/></Button>}
        
    </Col>
  
  )
}
