import React, { useEffect, useState } from "react";
import { Row, Col, Form } from "react-bootstrap";
import { FaTrashAlt } from "react-icons/fa";
import { useCourse } from "../context/CourseContext";
import { useSettings } from "../context/SettingContext";
import LearningObjectiveEditor from "./LearningObjectiveEditor";

export default function CourseEditor(props) {
  const { selectedCourse, setSelectedCourse, userCourses, getUserCourses } = useCourse();
  const { getSettingByName, settingData } = useSettings();

  useEffect(() => {
    if (!settingData.deliveryMethods) {
      getSettingByName("deliveryMethods");
    }
    getUserCourses(); // Fetch the list of user courses for prerequisites
  }, []);

  const handleInputChange = (event) => {
    const value = event.target.value;
    const path = event.target.name;
    const updatedCourse = { ...selectedCourse };

    const keys = path.split(".");
    keys.reduce((acc, key, index) => {
      if (index === keys.length - 1) {
        acc[key] = value;
      } else {
        if (!acc[key]) acc[key] = {};
      }
      return acc[key];
    }, updatedCourse);

    setSelectedCourse(updatedCourse);
  };

  const handlePrerequisiteChange = (event) => {
    const selectedOptions = Array.from(event.target.selectedOptions).map((option) => option.value);
    setSelectedCourse({ ...selectedCourse, prerequisites: selectedOptions });
  };

  const handleAddLearningGoal = () => {
    setSelectedCourse({
      ...selectedCourse,
      goals: [...(selectedCourse.goals || []), ""],
    });
  };

  const handleDeleteLearningGoal = (index) => {
    const updatedGoals = [...selectedCourse.goals];
    updatedGoals.splice(index, 1);
    setSelectedCourse({ ...selectedCourse, goals: updatedGoals });
  };

  const handleAddLearningObjective = () => {
    setSelectedCourse({
      ...selectedCourse,
      learningObjectives: [
        ...(selectedCourse.learningObjectives || []),
        { domain: "", level: "", verb: "", condition: "", masteryLevel: "", text: "", activities: [] },
      ],
    });
  };

  const handleDeleteLearningObjective = (index) => {
    const updatedObjectives = [...selectedCourse.learningObjectives];
    updatedObjectives.splice(index, 1);
    setSelectedCourse({ ...selectedCourse, learningObjectives: updatedObjectives });
  };

  const handleLearningObjectiveChange = (learningObjective, index) => {
    const updatedObjectives = [...selectedCourse.learningObjectives];
    updatedObjectives[index] = learningObjective;
    setSelectedCourse({ ...selectedCourse, learningObjectives: updatedObjectives });
  };

  return (
    <>
      <Row>
        <Col lg="3" md="4" xs="5" sm="4" className="appEditorLabel frameworkEditorLabel">
          <Form.Label>Course Name</Form.Label>
        </Col>
        <Col>
          <Form.Control
            name="name"
            type="text"
            value={selectedCourse.name || ""}
            onChange={handleInputChange}
          />
        </Col>
      </Row>

      <Row>
        <Col lg="3" md="4" xs="5" sm="4" className="appEditorLabel frameworkEditorLabel">
          <Form.Label>Course Code</Form.Label>
        </Col>
        <Col>
          <Form.Control
            name="code"
            type="text"
            value={selectedCourse.code || ""}
            onChange={handleInputChange}
          />
        </Col>
      </Row>

      <Row>
        <Col lg="3" md="4" xs="5" sm="4" className="appEditorLabel frameworkEditorLabel">
          <Form.Label>Description</Form.Label>
        </Col>
        <Col>
          <Form.Control
            name="description"
            type="text"
            value={selectedCourse.description || ""}
            onChange={handleInputChange}
          />
        </Col>
      </Row>

      <Row>
        <Col lg="3" md="4" xs="5" sm="4" className="appEditorLabel frameworkEditorLabel">
          <Form.Label>Delivery Method</Form.Label>
        </Col>
        <Col>
          <Form.Control
            as="select"
            name="deliveryMethod"
            value={selectedCourse.deliveryMethod || ""}
            onChange={handleInputChange}
          >
            {settingData?.deliveryMethods?.map((method, index) => (
              <option key={index} value={method}>
                {method}
              </option>
            ))}
          </Form.Control>
        </Col>
      </Row>
      <Row>
        <Col lg="3" md="4" xs="5" sm="4" className="appEditorLabel frameworkEditorLabel">
        <Form.Label>Duration</Form.Label>
        </Col>
        <Col>
        <Form.Control
        name="duration.facilitated"
        type="text"
        value={selectedCourse.duration.facilitated || 0}
        />
        </Col>
        <Col>
        <Form.Control
        name="duration.selfPaced"
        type="text"
        value={selectedCourse.duration.self_directed || 0}
        />
        </Col>
      </Row>

      <Row>
        <Col lg="3" md="4" xs="5" sm="4" className="appEditorLabel frameworkEditorLabel">
          <Form.Label>Prerequisites</Form.Label>
        </Col>
        <Col>
          <Form.Control
            as="select"
            multiple
            value={selectedCourse.prerequisites || []}
            onChange={handlePrerequisiteChange}
          >
            {userCourses
              .filter((course) => course._id !== selectedCourse._id) // Exclude the current course
              .map((course) => (
                <option key={course._id} value={course._id}>
                  {course.name}
                </option>
              ))}
          </Form.Control>
        </Col>
      </Row>

      <hr />

      <Row>
        <Col lg="11" md="11" xs="11" sm="11" className="appEditorLabel frameworkEditorLabel">
          Learning Goals
          <button title="Add Item" className="addDataItemButton" onClick={handleAddLearningGoal}>
            +
          </button>
        </Col>
      </Row>
      {selectedCourse.goals?.map((goal, index) => (
        <Row key={index}>
          <Col lg="11" sm="11" md="11" xs="11">
            <Form.Control
              name={`goals.${index}`}
              type="text"
              value={goal}
              onChange={handleInputChange}
            />
          </Col>
          <Col lg="1" sm="1" md="1" xs="1">
            <div className="appIcon">
              <FaTrashAlt onClick={() => handleDeleteLearningGoal(index)} />
            </div>
          </Col>
        </Row>
      ))}

      <hr />

      <Row>
        <Col lg="11" xs="11" sm="11" md="11" className="appEditorLabel frameworkEditorLabel">
          Learning Objectives
          <button title="Add Item" className="addDataItemButton" onClick={handleAddLearningObjective}>
            +
          </button>
        </Col>
      </Row>

      <Row>
        <Col>
          {selectedCourse.learningObjectives?.map((objective, index) => (
            <Row key={index}>
              <Col>
                <LearningObjectiveEditor
                  loKey={index}
                  learningObjective={objective}
                  onSave={(updatedObjective) => handleLearningObjectiveChange(updatedObjective, index)}
                />
              </Col>
              <Col lg="1" xs="1" sm="1" md="1">
                <div className="appIcon">
                  <FaTrashAlt onClick={() => handleDeleteLearningObjective(index)} />
                </div>
              </Col>
            </Row>
          ))}
        </Col>
      </Row>
    </>
  );
}
