import React, { useEffect, useState } from 'react';
import ReactFlow, { Background, Controls, MarkerType } from 'react-flow-renderer';
import dagre from 'dagre';
import { useCourse } from '../context/CourseContext';
import './CoursePrerequisitesReport.css';

const nodeWidth = 250;
const nodeHeight = 100;

export default function CoursePrerequisitesReport() {
  const { getUserCourses, userCourses } = useCourse();
  const [nodes, setNodes] = useState([]);
  const [edges, setEdges] = useState([]);

  useEffect(() => {
    const fetchCourses = async () => {
      await getUserCourses();
      if (userCourses?.length) {
        const { nodes: layoutedNodes, edges: layoutedEdges } = getLayoutedElements(
          userCourses
        );
        setNodes(layoutedNodes);
        setEdges(layoutedEdges);
      }
    };

    fetchCourses();
  }, [userCourses]);

  const getLayoutedElements = (courses) => {
    const dagreGraph = new dagre.graphlib.Graph();
    dagreGraph.setDefaultEdgeLabel(() => ({}));
    dagreGraph.setGraph({ rankdir: 'TB', marginx: 50, marginy: 50 });

    const newNodes = courses.map((course) => ({
      id: course._id,
      data: {
        label: (
          <div style={{ position: 'relative', textAlign: 'left' }}>
            {/* Tab for Course Code */}
            <div
              style={{
                position: 'absolute',
                top: '-40px',
                
                
                background: 'var(--yellow)',
                color: 'black',
                padding: '5px 10px',
                borderRadius: '10px 10px 0px 0px ',
                fontWeight: 'bold',
                boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
                zIndex: 10,
                width: '120px',
              }}
            >
              {course.code}
            </div>
            {/* Course Name */}
            <div style={{ color: 'black', fontWeight: 'bold', marginTop: '10px' }}>
              {course.name}
            </div>
          </div>
        ),
      },
      position: { x: 0, y: 0 },
      style: {
        width: nodeWidth,
        height: nodeHeight,
        border: '2px solid var(--blue)',
        borderRadius: '10px',
        background: 'var(--blue)',
        color: 'white',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontWeight: 'bold',
      },
    }));

    const newEdges = [];
    courses.forEach((course) => {
      if (course.prerequisites) {
        course.prerequisites.forEach((prerequisiteId) => {
          newEdges.push({
            id: `edge-${prerequisiteId}-${course._id}`,
            source: prerequisiteId,
            target: course._id,
            animated: true,
            markerEnd: {
              type: MarkerType.ArrowClosed,
            },
            style: {
              strokeWidth: 3,
              stroke: 'var(--blue)',
            },
          });
        });
      }
    });

    newNodes.forEach((node) => {
      dagreGraph.setNode(node.id, { width: nodeWidth, height: nodeHeight });
    });

    newEdges.forEach((edge) => {
      dagreGraph.setEdge(edge.source, edge.target);
    });

    dagre.layout(dagreGraph);

    const layoutedNodes = newNodes.map((node) => {
      const { x, y } = dagreGraph.node(node.id);
      return {
        ...node,
        position: { x, y },
        sourcePosition: 'top',
        targetPosition: 'bottom',
      };
    });

    return { nodes: layoutedNodes, edges: newEdges };
  };

  return (
    <div style={{ height: '90vh', width: '100%' }}>
      <h1 className="dependency-title">Courses Dependencies</h1>
      <ReactFlow
        nodes={nodes}
        edges={edges}
        fitView
        fitViewOptions={{ padding: 0.2 }}
      >
        <Background gap={20} color="#ddd" />
        <Controls />
      </ReactFlow>
    </div>
  );
}
