import React, { useRef, useEffect, useState } from 'react';
import { Row, Col, Button, Badge } from 'react-bootstrap';
import BloomDomainViewer from './BloomDomainViewer';
import CourseVisualBlock from './CourseVisualBlock'; // Import CourseVisualBlock
import './CourseView.css';
import { useSettings } from '../context/SettingContext';
import { FaBackward,FaBullseye, FaLightbulb, FaChalkboardTeacher, FaChevronDown, FaChevronRight, FaPrint, FaBarcode ,} from 'react-icons/fa';
import { useReactToPrint } from 'react-to-print';
import { useCourse } from '../context/CourseContext';

export default function CourseView(props) {
  const { getSettingByName } = useSettings();
  const {userCourses,getUserCourses}=useCourse();
  const courseRef = useRef();

  // State to manage toggle for each activity content
  const [contentVisibility, setContentVisibility] = useState({});

  useEffect(() => {
    getSettingByName('domains');
    getUserCourses();
  }, []);
  const prerequisitesCourseInfo=userCourses.filter(course=>props.data?.prerequisites?.includes(course._id));

  // Function to handle printing the course report
  const handlePrint = useReactToPrint({
    content: () => courseRef.current,
    documentTitle: props.data?.name || 'Course Report',
  });

  if (!props.data?.name) {
    return (
      <>
        <h1 className="programInformation">Course Information</h1>
        <p>No course selected</p>
      </>
    );
  }

  // Toggle visibility of content for a specific activity
  const toggleContentVisibility = (activityIndex) => {
    setContentVisibility((prevVisibility) => ({
      ...prevVisibility,
      [activityIndex]: !prevVisibility[activityIndex],
    }));
  };

  return (
    <div ref={courseRef}>
      <Row>
        <Col lg="12" md="12" sm="12" sx="12" className="courseInformation">
          Course Information
          <button
            title="Print"
            onClick={handlePrint}
            className="btn btn-primary printButton"
            style={{ marginTop: '20px' }}
          >
            <FaPrint />
          </button>
        </Col>
      </Row>

      {props.data.name !== undefined ? (
        <div className="viewContainer">
          {/* Basic Course Information */}
          <Row>
            <Col lg="3" md="3" sm="5" sx="6" className="courseCodeTop">
              <b>
                <FaBarcode /> {props.data.code}
              </b>
            </Col>
          </Row>
          <Row>
            <Col className="courseName">
              {props.data.name} Course{' '}
              <Badge style={{ float: 'right' }}>Delivered {props.data.deliveryMethod}</Badge>
            </Col>
          </Row>
          <Row className="courseDescription">
            <Col className="viewValue">
              <p>
                <b>Description: </b>
                {props.data.description}
              </p>
            </Col>
          </Row>

          {/* Prerequisites Section */}
          {prerequisitesCourseInfo?.length > 0 && (
            <>
              <Row>
                <Col lg="12" md="12" sm="12" sx="12" className="viewLabel h2">
                <FaBackward /> Prerequisite Courses
                </Col>
              </Row>
              <Row>
                {prerequisitesCourseInfo.map((prerequisite, index) => (
                  <CourseVisualBlock key={index} course={prerequisite} />
                ))}
              </Row>
            </>
          )}

          <br />
          {/* Learning Goals Section */}
          <Row>
            <Col lg="12" md="12" sm="12" sx="12" className="viewLabel h2">
              <FaLightbulb /> Learning Goals
            </Col>
          </Row>
          {props.data.goals.map((goal, index) => (
            <Row key={'g' + index}>
              <Col lg="12" md="12" sm="12" sx="12" className="learningGoalRow">
                <FaLightbulb /> {goal}
              </Col>
            </Row>
          ))}

          <br />
          {/* Learning Objectives Section */}
          <Row>
            <Col lg="12" md="12" sm="12" sx="12" className="viewLabel h2">
              <FaBullseye /> Learning Objectives
            </Col>
          </Row>
          {props.data.learningObjectives.map((learningObjective, index1) => (
            <div className="learningObjectiveBlock" key={'lo' + index1}>
              <Row>
                <Col className="learningObjectiveText">
                  <FaBullseye /> &nbsp;{learningObjective.text}
                  <hr />
                </Col>
              </Row>

              {/* Related Activities Section with Toggle */}
              <Row>
                <Col lg="4" md="6" sm="5" sx="12" className="domainContainer">
                  <BloomDomainViewer
                    domainName={learningObjective.domain}
                    levelName={learningObjective.level}
                    masteryLevel={learningObjective.masteryLevel}
                  />
                </Col>
                <Col>
                  <Row>
                    <div className="viewLabel h3">
                      <FaChalkboardTeacher /> Related Activities
                    </div>
                    {learningObjective.activities.map((activity, index2) => (
                      <Col
                        lg={contentVisibility[index2] ? 12 : 6}
                        md={contentVisibility[index2] ? 12 : 6}
                        sm={contentVisibility[index2] ? 12 : 6}
                        sx="12"
                        className="activityBlock"
                        key={'a' + index2}
                      >
                        <div className="activityName">{activity.name}</div>
                        <div>
                          <strong>Activity Type: </strong>
                          {activity.type}
                        </div>
                        <div>
                          <strong>Phase: </strong>
                          {activity.phase}
                        </div>
                        <div>
                          <strong>Description: </strong>
                          {activity.description}
                        </div>

                        {/* Toggle Button */}
                        <Button
                          variant="secondary"
                          onClick={() => toggleContentVisibility(index2)}
                          className="toggleContentButton"
                        >
                          {contentVisibility[index2] ? <FaChevronDown /> : <FaChevronRight />} Show
                          Content
                        </Button>

                        {/* Activity Content */}
                        {contentVisibility[index2] && (
                          <div className="activityContent">{activity.content}</div>
                        )}
                      </Col>
                    ))}
                  </Row>
                </Col>
              </Row>
            </div>
          ))}
        </div>
      ) : (
        <div>
          <Row>
            <Col>Please select a course</Col>
          </Row>
        </div>
      )}
    </div>
  );
}
